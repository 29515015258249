import React from 'react';
import styled from 'styled-components';
import {
    CompetenceContainer,
    CompetenceHeader,
    CompetenceList,
    CompetenceItem,
    CategoryContainer
} from './StyledCompetenceComponents';

const MainTitle = styled.h1`
  font-size: 3em;
  color: #4299E1;
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
  margin-top: -23vh;
`;

const OurCompetences = () => {
    const developmentSkills = [
        "Java 18+", "Git", "JUnit", "Spring", "Spring boot", ".NET Framework", "ASP.NET",
        "C#", "ASP.NET MVC5", "Javascript", "Entity Framework", "TDD (Test Driven Development)",
        "Microservices", ".NET Core", "JPA (Java Persistence API)"
    ];

    const deploymentIntegrationSkills = [
        "Maven", "OpenShift", "Docker", "Jenkins", "CI (Continuous Integration)",
        "CD (Continuous Delivery)", "Gitlab", "Azure Devops", "Scrum", "RabbitMq", "Kafka"
    ];

    const architecturePlatformSkills = [
        "AWS", "Google Cloud Platform (GCP)", "Amazon Web Services (AWS)", "Serverless Architecture",
        "Terraform", "Kubernetes", "NoSQL", "SSL/TSL", "GraphQL", "IBM MQ"
    ];

    const databaseStorageSkills = [
        "Mongo Atlas", "SQL/T-SQL", "MS SQL", "Hibernate", "PostgreSQL", "SQLite", "Oracle DB",
        "Entity Framework Core", "MySQL", "Redis", "Elasticsearch", "Cassandra", "DynamoDB", "CosmosDB"
    ];

    const apiCommunicationSkills = [
        "REST / RESTful API", "Swagger", "API, SOAP", "Kafka", "GraphQL", "IBM MQ", "gRPC",
        "WebSockets", "SignalR", "OData", "ActiveMQ",
        "RabbitMQ", "Apache Camel", "ServiceStack"
    ];

    return (
        <>
            <MainTitle>Our Competences</MainTitle>
            <CompetenceContainer>
                <CategoryContainer>
                    <CompetenceHeader>Development</CompetenceHeader>
                    <CompetenceList>
                        {developmentSkills.map(skill => <CompetenceItem key={skill}>{skill}</CompetenceItem>)}
                    </CompetenceList>
                </CategoryContainer>

                <CategoryContainer>
                    <CompetenceHeader>Deployment & Integration</CompetenceHeader>
                    <CompetenceList>
                        {deploymentIntegrationSkills.map(skill => <CompetenceItem key={skill}>{skill}</CompetenceItem>)}
                    </CompetenceList>
                </CategoryContainer>

                <CategoryContainer>
                    <CompetenceHeader>Architecture & Platforms</CompetenceHeader>
                    <CompetenceList>
                        {architecturePlatformSkills.map(skill => <CompetenceItem key={skill}>{skill}</CompetenceItem>)}
                    </CompetenceList>
                </CategoryContainer>

                <CategoryContainer>
                    <CompetenceHeader>Databases & Storage</CompetenceHeader>
                    <CompetenceList>
                        {databaseStorageSkills.map(skill => <CompetenceItem key={skill}>{skill}</CompetenceItem>)}
                    </CompetenceList>
                </CategoryContainer>

                <CategoryContainer>
                    <CompetenceHeader>APIs & Communication</CompetenceHeader>
                    <CompetenceList>
                        {apiCommunicationSkills.map(skill => <CompetenceItem key={skill}>{skill}</CompetenceItem>)}
                    </CompetenceList>
                </CategoryContainer>
            </CompetenceContainer>
        </>
    );
};

export default OurCompetences;
