import styled from 'styled-components';

const CompetenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 40px;
  color: #FFFFFF;
`;

const CompetenceHeader = styled.h2`
  font-size: 2em;
  color: #4299E1; // Soft Blue
  margin-bottom: 20px;
`;

const CompetenceList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; // Space between items
`;

const CategoryContainer = styled.div`
    flex: 1;
    padding: 0 20px; // This gives some space between the columns
`;

const CompetenceItem = styled.li`
  background-color: #4299E1; // Soft Blue
  color: #FFFFFF; // White
  border-radius: 20px;
  padding: 5px 15px;
  font-size: 0.9em;
  display: inline-block;
  margin: 5px 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2B6CB0; // A slightly darker blue on hover
  }
`;

export { CompetenceContainer, CompetenceHeader, CompetenceList, CompetenceItem, CategoryContainer };
