import React, {useCallback} from 'react';
import {FaUserCircle} from 'react-icons/fa';
import Particles from 'react-tsparticles';
import {loadSlim} from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import OurCompetences from './OurCompetences';
import logoImage from './logo.png';
import samImage from './sam.png';
import arashImage from './arash.jpg';
import pawelImage from './pawel.png';
import alexanderImage from './alex.png';
import styled, {createGlobalStyle, keyframes} from 'styled-components';
import {CloseButton} from "reactstrap";


const HomePageContainer = styled.div`
  position: relative;
  background: rgba(26, 32, 44, 0.73); /* Dark Blue-Gray */
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Section = styled.section`
      padding-top: 29vh;
      padding-bottom: 39vh;
    scroll-snap-align: start;
    overflow: hidden;
    text-align: center;
`;

const TeamSection = styled(Section)`
    display: flex;
    justify-content: center;
    flex-wrap: wrap; // To handle responsiveness if you have many team members
`;

const Title = styled.h1`
  font-size: 4em;
  color: #4299E1;  // Soft Blue
`;

const MemberImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;  // This makes the image circular
  object-fit: cover;   // Ensures the image covers the full width/height
  overflow: hidden;    // Ensures no part of the image spills out
`;


const TeamMember = styled.div`
  margin: 20px;
  text-align: center;
  flex-basis: calc(100% / 4 - 40px);  // Adjusted for 4 team members with 20px margins on both sides
`;

const MemberName = styled.h3`
  color: #FFFFFF;  // White
  margin: 10px 0;
`;

const Role = styled.p`
  color: #A0AEC0;  // Light Gray
`;

const IntroductionContainer = styled(Section)`
  background-color: rgba(26, 32, 44, 0.8);
  color: #FFFFFF;
  padding: 40px 20px; // Added horizontal padding
  text-align: left; // Ensure left alignment
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto; // Centering the container
  width: 100%; // Ensuring the container spans the full width
`;


const IntroductionTitle = styled.h2`
    font-size: 2em;
    color: #4299E1; // Soft Blue for the title
    margin-bottom: 20px;
`;

const IntroductionText = styled.p`
    font-size: 1em;
    margin-bottom: 20px;
    line-height: 1.5; // To improve readability
`;

const Signature = styled.p`
    font-size: 1.2em;
    margin-top: 40px;
`;

const ContactLink = styled.a`
    color: #4299E1; // Soft Blue for links
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

const ParticlesBackground = styled(Particles)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;  // This ensures that the particles are "behind" the other content
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
  }

  /* Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 10px; // Adjust the width as per your preference
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Makes the track "disappear" */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(66, 153, 225, 0.29); // Soft Blue for the thumb
    border-radius: 5px; // Optional: To make the thumb rounded
    border: 2px solid rgba(26, 32, 44, 0.73); // Dark Blue-Gray border to match the background
  }

`;

const LogoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const OffCanvas = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.show ? '0' : '-250px'};  // Hide or show based on the state
  height: 100vh;
  width: 250px;
  background: rgba(26, 32, 44, 0.9);
  transition: right 0.3s;
  z-index: 10;
  padding-top: 18px;
`;

const MenuButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 99;
`;

const Bar = styled.span`
  display: block;
  width: 25px;
  height: 3px;
  margin: 4px 0;
  background-color: #FFF;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const InputField = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const LoginButton = styled.button`
  padding: 10px;
  background-color: #4299E1; // Soft Blue
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2b7cb9; // A bit darker blue for hover state
  }
`;

const popOut = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const SpeechBubble = styled.div`
  position: absolute;
  bottom: 90px;
  right: 10px;
  background-color: #4299e17d;
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 250px;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0);
  opacity: 0;
  animation: ${popOut} 1s forwards 0.5s, ${fadeOut} 1s forwards 20.5s;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #4299e17d;
    transform: translateY(-50%);
  }

  // Media query for devices with a smaller height
  @media (max-height: 700px) {
    max-width: 200px;       // Reducing width
    padding: 5px 15px;      // Adjusting padding
    font-size: 0.85em;      // Reducing font size
    bottom: 80px;
    
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;   // Adjust this if needed to move the triangle closer or farther
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #4299e17d;
      transform: translateY(-50%);
      margin-right: 0; // Adding a negative margin to remove any potential gap
    }
  }
`;





const HomePage = () => {



    const [showOffCanvas, setShowOffCanvas] = React.useState(false);

    const toggleOffCanvas = () => {
        setShowOffCanvas(!showOffCanvas);
    }

    const handleLogin = (e) => {
        e.preventDefault();
        const email = e.target.elements.email.value;
        const password = e.target.elements.password.value;

        // Handle login logic here (e.g., send these values to an authentication API)

        console.log("Email:", email, "Password:", password);
    };

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);


    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);


    const team = [
        { name: "Pawel Banek", role: "Business Manager/Developer", email: "pawel.banek@r1.consulting", image: pawelImage },
        { name: "Sam Junttila", role: "Java Developer", email: "sam@r1.consulting", image: samImage },
        { name: "Arash Safari", role: "Tech Lead, DevOps, .NET Developer", email: "arash.safari@r1.consulting", image: arashImage },
        { name: "Alexander Papadakis", role: ".NET Developer", email: "alexander.papadakis@r1.consulting", image: alexanderImage },
    ];

    return (
        <>
            <GlobalStyle />
            <MenuButton onClick={toggleOffCanvas}>
                <Bar />
                <Bar />
                <Bar />
            </MenuButton>
            <OffCanvas show={showOffCanvas}>
                <CloseButton onClick={toggleOffCanvas}>&times;</CloseButton>

                <LoginForm onSubmit={handleLogin}>
                    <InputField type="email" name="email" placeholder="Email" required />
                    <InputField type="password" name="password" placeholder="Password" required />
                    <LoginButton type="submit">Login</LoginButton>
                </LoginForm>
            </OffCanvas>
        <HomePageContainer>
            <ParticlesBackground
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{
                    background: {
                        color: {
                            value: "#1a202c",
                        },
                    },
                    fpsLimit: 120,
                    interactivity: {
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "repulse",
                            },
                            resize: true,
                        },
                        modes: {
                            push: {
                                quantity: 4,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: {
                                default: "bounce",
                            },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                area: 800,
                            },
                            value: 80,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            value: { min: 1, max: 5 },
                        },
                    },
                    detectRetina: true,
                }}
            />

            <Section>
                    <LogoImage src={logoImage} alt="Logo" />
                <SpeechBubble>👋 Hey there! Is your team in search of tech-savvy developers? Dive into our expertise in Java, .NET Core, Kubernetes, DevOps, RESTful API, and beyond! 🚀 Let's collaborate and craft tech-forward solutions together! 🏆</SpeechBubble>
            </Section>
            <IntroductionContainer>
                <IntroductionTitle>Welcome to R1 Consulting AB</IntroductionTitle>
                <IntroductionText>
                    Founded by Sam Junttila, Arash Safari, and Pawel Banek, R1 Consulting AB is the culmination of over 20 years of combined experience and a shared passion for technological advancement.
                </IntroductionText>
                <IntroductionText>
                    We pride ourselves on offering unmatched expertise in modern Java and .Net development and significant proficiency in cloud engineering. This blend of industry-leading technical skills positions us as a top choice for even the most demanding projects.
                </IntroductionText>
                <IntroductionText>
                    We're excited to announce the recent addition of Alexander Papadakis to our team—a highly skilled .Net developer. With a proven track record in .Net development, Alexander's dedication and technical insights fortify our project capabilities. We're currently seeking .Net projects in Stockholm for Alexander, though we're also open to remote opportunities.
                </IntroductionText>
                <IntroductionText>
                    But at R1 Consulting, we understand that technical knowledge alone doesn't guarantee project success. Our team is also deeply business-oriented, ensuring we stay abreast of the latest technologies, frameworks, and best practices within our field. This approach grants us the unique ability to swiftly adapt to evolving tech demands and to truly comprehend and meet our clients' distinct needs.
                </IntroductionText>
                <IntroductionText>
                    We'd love the opportunity to collaborate and bring our expertise to your next project. Thank you for considering us, and we hope to connect with you soon.
                </IntroductionText>
                <Signature>
                    Warm regards, <br />
                    Sam Junttila <br />
                    📧 <ContactLink href="mailto:sam@r1.consulting">sam@r1.consulting</ContactLink>
                </Signature>
            </IntroductionContainer>
            <Section>
                <OurCompetences />
            </Section>
            <TeamSection>
                {team.map(member => (
                    <TeamMember key={member.name}>
                        <MemberImage src={member.image} alt={member.name} />
                        <MemberName>{member.name}</MemberName>
                        <Role>{member.role}</Role>
                        <ContactLink href={"mailto:" + member.email}>{member.email}</ContactLink>
                    </TeamMember>
                ))}

            </TeamSection>
        </HomePageContainer>
            </>
    );
}

export default HomePage;
